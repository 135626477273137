import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const TagsFilter = ({ tags, activeTag, handleSetActiveTag }) => {
  return (
    <TagList className="tags-filter-list">
      {tags.map(({ node: { id, title } }) => (
        <Tag key={id} className="tag">
          <Button className="tag__button" active={activeTag === id} handleClick={() => handleSetActiveTag(id)}>
            {title}
          </Button>
        </Tag>
      ))}
      <Tag className="tag">
        <Button className="tag__button" active={activeTag === null} handleClick={() => handleSetActiveTag(null)}>
          All
        </Button>
      </Tag>
    </TagList>
  );
};

const TagList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  width: ${({ theme }) => theme.sizes.content + 100}px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Tag = styled.li`
  margin: .5rem 1rem;
`;

export default TagsFilter;