import React, { useState } from 'react';
import styled from 'styled-components';

import Resource from './Resource/Resource';
import { contentWidth } from './theme';

const Resources = ({ resources, home = false }) => {
  const [active, setActive] = useState(null);

  return (
    <ResourcesList className="resources-list">
      {resources.map(resource => (
        <Item className="resources-list__item" key={resource.node.id}>
          <Resource
            {...resource}
            active={home ? active : null}
            setActive={home ? setActive : null}
          />
        </Item>
      ))}
    </ResourcesList>
  );
};

const ResourcesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 1rem;
  ${contentWidth}
  margin: 5rem auto;
`;

const Item = styled.li`
  color: ${({ theme }) => theme.darkOrange};
  font-family: ${({ theme }) => theme.font.boldFamily};
  font-weight: 700;
  font-size: 1.8rem;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

export default Resources;
