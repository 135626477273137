import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Global/Wrapper';
import SingleCategory from '../components/SingleCategory';

const CategoryPage = ({ data }) => {
  const { sanityResourceCategory, allSanityResource } = data;
  return (
    <Wrapper
      title={sanityResourceCategory.title}
      seoSettings={sanityResourceCategory.seoSettings}
    >
      <SingleCategory
        {...sanityResourceCategory}
        resources={allSanityResource}
      />
    </Wrapper>
  );
};

export const categoryQuery = graphql`
  query categoryQuery($id: String!) {
    sanityResourceCategory(id: { eq: $id }) {
      id
      title
      _rawDescription(resolveReferences: { maxDepth: 10 })
      icon {
        asset {
          fixed(width: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
      headerImage {
        asset {
          fixed(width: 1200) {
            ...GatsbySanityImageFixed
          }
        }
      }
      slug {
        current
      }
      seoSettings {
        metaDescription
        pageTitle
      }
    }
    allSanityResource(
      filter: { resourceCategories: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          title
          resourceTags {
            id
            title
          }
          resourceType
          _rawBody
          externalLink
          document {
            asset {
              id
              url
            }
          }
          slug {
            current
          }
          resourcePage {
            title
            mainImage {
              asset {
                fixed(width: 1200) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CategoryPage;
