import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useSpring } from 'react-spring';
import classNames from 'classnames';
import Content from '../Content';

const AccordionResource = ({
  title,
  _rawBody,
  className,
  active,
  setActive,
  id,
}) => {
  const accordionRef = useRef(null);
  const titleRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (setActive) {
      setActive(active ? null : id);
    } else {
      setOpen(!open);
    }
  };

  const isOpen = setActive ? active : open;

  const contentHeight = accordionRef.current
    ? accordionRef.current.firstChild.clientHeight
    : 0;

  const accordionStyles = useSpring({
    height: isOpen ? `${contentHeight}px` : '0px',
    marginTop: isOpen ? '2rem' : '0rem',
    marginBottom: isOpen ? '2rem' : '0rem',
  });

  return (
    <Accordion
      className={classNames('resource', className)}
      contentOpen={isOpen}
    >
      <ResourceTitle
        className="resource__title"
        ref={titleRef}
        onClick={handleOpen}
        contentOpen={isOpen}
      >
        {title}
      </ResourceTitle>
      <Content
        ref={accordionRef}
        className="resource__content"
        styles={accordionStyles}
      >
        {_rawBody}
      </Content>
    </Accordion>
  );
};

const Accordion = styled.div`
  overflow: hidden;
  .content-wrapper {
    line-height: 1.5;
  }
`;

const ResourceTitle = styled.button`
  background: transparent;
  border: 0;
  text-align: left;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  outline: none;
  cursor: pointer;
  ::after {
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    /* border-color: transparent; */
    border-left: 10px solid ${({ theme }) => theme.red};
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 0 solid transparent;
    display: inline-block;
    margin-left: 1rem;
    transition: 0.25s;
    transform: rotate(${({ contentOpen }) => (contentOpen ? '90deg' : '0deg')});
  }
`;

export default AccordionResource;
