import React from 'react';

import AccordionResource from './AccordionResource';
import ExternalLinkResource from './ExternalLinkResource';
import DocumentResource from './DocumentResource';
import PageResource from './PageResource';
import NoTypeResource from './NoTypeResource';

const Resource = ({ node, active, setActive }) => {
  const { resourceType } = node;
  if (resourceType && resourceType[0] === 'content') {
    return (
      <AccordionResource
        className="accordion-resource"
        {...node}
        active={active ? active === node.id : null}
        setActive={setActive}
      />
    );
  }
  if (resourceType && resourceType[0] === 'externalLink') {
    return (
      <ExternalLinkResource className="external-link-resource" {...node} />
    );
  }
  if (resourceType && resourceType[0] === 'document') {
    return <DocumentResource className="document-resource" {...node} />;
  }
  if (resourceType && resourceType[0] === 'page') {
    return <PageResource className="document-page" {...node} />;
  }
  return <NoTypeResource className="no-type" {...node} />;
};

export default Resource;
