import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import Content from './Content';
import PageTitle from './PageTitle';
import SiteContext from './SiteContext';
import { unique } from './utils';
import TagsFilter from './TagsFilter';
import Resources from './Resources';

import { PageMain } from './layout';

const uniqueTags = resources => {
  return resources.edges.map(resource => {
    return resource.node.resourceTags;
  }).reduce((acc, val) => acc.concat(val), []).map(tag => {
    return tag.id;
  }).filter(unique);
}

const SingleCategory = ({ title, _rawDescription, headerImage, resources }) => {

  const { tags } = useContext(SiteContext);
  const justTagIds = uniqueTags(resources);
  const filteredTags = tags.edges.filter(edge => edge.node.id.indexOf(justTagIds));

  const [activeTag, setActiveTag] = useState(null);

  const filteredResources = resources.edges.filter(({ node: { resourceTags }}) => {
    if (activeTag === null) return true;
    return resourceTags.findIndex(tag => tag.id === activeTag) !== -1;
  });

  return (
    <SingleCategoryWrapper className="single-category-wrapper">
      <PageTitle headerImage={headerImage ? headerImage.asset.fixed.src : null} className="category-title">{title}</PageTitle>
      {_rawDescription && (
        <PageMain>
          <Content>{_rawDescription}</Content>
        </PageMain>
      )}
      {resources.edges.length > 0 && (
        <>
          <TagsFilter
            tags={filteredTags}
            activeTag={activeTag}
            handleSetActiveTag={setActiveTag}
          />
          <Resources resources={filteredResources} />
        </>
      )}
    </SingleCategoryWrapper>
  );

};

const SingleCategoryWrapper = styled.div`
  .content-wrapper {
    line-height: 1.5;
  }
`;

export default SingleCategory;