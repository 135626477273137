import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import classNames from 'classnames';

const Button = ({ children, active = false, handleClick, className}) => {
  return (
    <StyledButton className={classNames('button', className)} active={active} onClick={handleClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  border: 0;
  transition: .15s;
  background: ${({ active, theme }) => active ? theme.blue : darken(.1, theme.blue)};
  color: white;
  font-family: ${({ theme }) => theme.font.boldFamily};
  font-weight: 700;
  padding: 1rem 2rem;
  border-radius: 10rem;
  cursor: pointer;
  outline: none;
`;

export default Button;