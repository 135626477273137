import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import classNames from 'classnames';

const PageResource = ({ title, slug, className }) => {
  return (
    <PageLinkWrapper className={classNames('resource', className)}>
      <Link to={`/resource/${slug.current}`}>{title}</Link>
    </PageLinkWrapper>
  );
};

const PageLinkWrapper = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default PageResource;