import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const ExternalLinkResource = ({ id, externalLink, title, className }) => {
  return (
    <ExternalLink
      className={classNames('resource', className)}
      href={externalLink}
      target="_blank"
    >
      {title}
    </ExternalLink>
  );
};

const ExternalLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

export default ExternalLinkResource;