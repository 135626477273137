import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const DocumentResource = ({ document, title, className }) => {
  return (
    <DocumentLink href={document.asset.url} className={classNames('resource', className)} target="_blank">
      {title}
    </DocumentLink>
  );
};

const DocumentLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

export default DocumentResource;